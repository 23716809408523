<script setup>
import {defineProps, ref, computed, onMounted} from 'vue';
import useUtils from '../../Composables/useUtils';
import Lance from '../Leilao/Lance';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';
import useLeilaoLocalComUpdates from '../../Composables/useLeilaoLocalComUpdates';
import useLote from '../../Composables/useLote';
import useGlobals from '../../Composables/useGlobals';
import useRotas from '../../Composables/useRotas';


const route = useRotas();

const props = defineProps({
    leilaoInit: Object,
    loteInit: Object,
    habilitado: Boolean,
    loteLeiloeiro: Number
});

const lanceLocalAutomatico = ref(false);
const lanceAutomatico = ref([]);
const temLanceAtomatico = ref(false);

const valorLanceAuditorio = ref(0);
const abrindoLanceDireitoPreferencia = (valorLance) => {
    valorLanceAuditorio.value = valorLance;
    LanceLocalDireitoPreferencia.value = true;
};
const abrindoLance = (valorLance) => {
    valorLanceAuditorio.value = valorLance;
    lanceLocal.value = true;
};

const abrindoLanceAutomatico = (valorLance) => {
    valorLanceAuditorio.value = valorLance;
    lanceLocalAutomatico.value = true;
};

const atualizarLanceAutomatico = async () => {
    lanceAutomatico.value = await (await fetch(route('auditorio.temLanceAutomatico', {idLote: lote.value.id}))).json();
    temLanceAtomatico.value = lanceAutomatico.value.tem_lance_automatico;
}

onMounted(async() =>{

    await atualizarLanceAutomatico();

});

const {
    lote
} = useLoteLocalComUpdates(props)

const {
    leilao
} = useLeilaoLocalComUpdates(props)

const {
    getImagem,
    formatDate,
    formatador,
    formatadorLote
} = useUtils();

const {
    autoplay,
    slide,
    timer,
    lanceProprio,
    timerModerador,
} = useLote(leilao, lote);

const {
    logoLeiloeiro,
    usernameLogado,
    logado,
    idClienteLogado
} = useGlobals();

const habilitandoLocal = ref(false);
const lanceLocal = ref(false);
const LanceLocalDireitoPreferencia = ref(false);

const urlVideo = computed(() => {
    //return `${leilao.value.link_transmissao}?rel=0&autoplay=1&loop=1${!(window.usuarioInteragiu.value && false)?'&mute=1':''}`;
    return `${leilao.value.link_transmissao}?rel=0&autoplay=1&loop=1`;
});
</script>

<template>
    <q-card class="full-width full-height">
        <q-card-section
            style="height: 20px;transition: all .75s ease-in-out"
            :style="`background-color: ${lote.status_lote?.cor_site};`"
            class="text-subtitle1 text-white text-center column justify-center">
            {{ lote.status_lote?.nome }}
        </q-card-section>
        <q-card-section class="q-pa-none">
            <q-card-section class="q-pa-none q-mb-xs flex row">
                <q-carousel
                    v-model="slide"
                    :autoplay="false"
                    animated
                    arrows
                    style="height: 25vh;"
                    control-color="white"
                    control-text-color="primary"
                    control-type="push"
                    next-icon="mdi-chevron-right"
                    prev-icon="mdi-chevron-left"
                    infinite
                    swipeable
                    :class="($q.screen.gt.sm) ? 'col-5' : 'col-12'"
                    @mouseenter="autoplay = false"
                    @mouseleave="autoplay = true"
                >
                    <q-carousel-slide
                        v-if="+leilao.transmissao_iniciada === 1 && lote.id == loteLeiloeiro"
                        :name="1"
                        style="background-position: center;background-size: cover;background-repeat: no-repeat"
                        class="q-pa-none">
                        <keep-alive>
                            <q-video
                                :key="`live-${leilao.id}`"
                                class="absolute-full"
                                :src="urlVideo"
                            />
                        </keep-alive>
                        <q-carousel-control v-if="+leilao.fechamento_automatico === 0 && lote.timer_moderador && timerModerador">
                            <q-chip :color="lote.proximoStatus.cor" text-color="white">
                                {{ timerModerador }}
                            </q-chip>
                        </q-carousel-control>
                        <q-carousel-control v-else>
                            <q-chip color="primary" text-color="white">
                                AO VIVO
                            </q-chip>
                        </q-carousel-control>
                    </q-carousel-slide>
                    <q-carousel-slide
                        v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0  ? lote.imagens_lote : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))"
                        :key="key" :name="((+leilao.transmissao_iniciada === 1 && lote.id == loteLeiloeiro)?2:1)+key"
                        style="background-position: center;background-size: cover;background-repeat: no-repeat"
                        class="q-pa-none">
                        <q-img
                            class="full-width full-height"
                            loading="lazy"
                            :src="getImagem(imagem?.arquivo, true, lote.status_lote?.identificador)"
                            :ratio="16/9"
                        >
                            <template v-slot:error>
                                <div class="absolute-full flex flex-center bg-primary text-white">
                                    Houve um problema carregando a imagem
                                </div>
                            </template>
                            <template v-slot:loading>
                                <div class="absolute-full flex column flex-center bg-white text-primary">
                                    <img :src="logoLeiloeiro" style="width: 40%" />
                                    <p class="q-mt-sm">
                                        <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                    </p>
                                </div>
                            </template>
                        </q-img>
                    </q-carousel-slide>
                </q-carousel>
                <div :class="($q.screen.gt.sm) ? 'col-7' : 'col-12'"
                >
                    <div class="full-width q-px-md q-pt-md">
                        <h5 class="q-ma-none text-primary">
                            {{formatadorLote(lote.sequencia)}} - {{ lote.titulo }}
                        </h5>
                    </div>
                    <q-list class="full-width">
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-label-multiple-outline"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label>{{ lote.titulo }}</q-item-label>
                                <q-item-label style="max-height: 7vh;overflow-y: auto" class="nopad" caption v-html="lote.descricao">
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 1 && +leilao.tipo_fechamento === 1 && lote.proximoStatus.timestamp" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{timer}}</q-item-label>
                                <q-item-label caption>{{lote.proximoStatus.texto}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 0 && lote.timer_moderador && timerModerador" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{timerModerador}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="+leilao.fechamento_automatico === 1 && (lote.proximoStatusTime) && (leilao.tipo_fechamento != '0' || lote.status_lote.identificador == 'EM PREGAO')" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-timer"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label class="text-bold text-h5 text-primary">{{lote.proximoStatusInterval}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" name="mdi-calendar"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label
                                    :style="+leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through'  : ''"
                                    :caption="(+leilao.primeiro_leilao_concluido === 1 && +leilao.dois_leiloes === 1) ? true : false"
                                >
                                    1º Leilão: {{ formatDate(leilao.data_hora_inicio) }}
                                </q-item-label>
                                <q-item-label
                                    :style="+leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through'  : ''"
                                    :caption="(+leilao.primeiro_leilao_concluido === 1) ? false : true"
                                    v-if="leilao.dois_leiloes === '1'"
                                >
                                    2º Leilão: {{ formatDate(leilao.data_hora_inicio_segundo_leilao) }}
                                </q-item-label>
                                <q-item-label v-else caption>
                                    Sem Segundo Leilão
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="positive" name="mdi-cash"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label v-if="!(lote.lanceAtual > 0)">Lance Inicial {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}</q-item-label>
                                <q-item-label v-if="lote.lanceAtual > 0">Lance Atual {{ formatador.format(lote.lanceAtual) }}</q-item-label>
                                <q-item-label caption v-if="lote.lanceAtual > 0">Lance Inicial {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1 && +leilao.dois_leiloes === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon color="positive" name="mdi-cash-fast"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label>Próximo Lance {{ formatador.format(lote.proximoLance) }}</q-item-label>
                                <q-item-label caption>Incremento {{ formatador.format(lote.incrementoLote) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
            </q-card-section>
            <q-card-section class="q-pa-none" style="flex: 4">
                <div class="full-width flex row">
                    <div :class="($q.screen.gt.sm) ? 'col-6' : 'col-12'" class="q-px-xs column">
                        <q-card class="flex column no-wrap full-width shadow-0">
                            <q-toolbar class="bg-primary text-white">
                                <q-icon name="mdi-gavel" />
                                <q-toolbar-title>
                                    Lances
                                </q-toolbar-title>
                            </q-toolbar>
                            <q-list style="height: 30vh; overflow-y: auto;">
                                <q-item v-for="(lance, idx) in lote.lances" :key="idx" clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon :color="(idx === 0) ? 'positive' : 'primary'" size="lg" name="mdi-cash"/>
                                    </q-item-section>

                                    <q-item-section>


                                        <q-item-label  overline>
                                            <template v-if="idx === 0">
                                                Lance Atual
                                            </template>
                                            <template v-else>
                                                Lance
                                            </template>
                                            <template v-if="+lote.leilao.mostrar_forma_pagamento_lance === 1">
                                                <template v-if="+lance.parcelado === 1">
                                                    Parcelado em {{+lance.qtd_parcelas}} vezes
                                                </template>
                                                <template v-else>
                                                    à Vista
                                                </template>
                                            </template>
                                        </q-item-label>


                                        <q-item-label  :class="(idx === 0) ? 'text-positive text-h6' : 'text-primary text-subtitle1'">
                                            {{ formatador.format(lance.valor) }}
                                        </q-item-label>

                                        <q-item-label overline style="font-size: 70%" v-if="+lote.leilao.mostrar_apelido === 0">
                                            por <b
                                            :class="(lance.cliente.usuario.username === usernameLogado) ? 'text-positive' : 'text-primary'">{{ (lance.cliente.usuario.username === usernameLogado) ? 'Você' : lance.cliente.id }}</b>
                                            em <b>{{ formatDate(lance.data_hora) }}</b>
                                        </q-item-label>
                                        <q-item-label overline style="font-size: 70%" v-else>
                                            por <b
                                            :class="(lance.cliente.usuario.username === usernameLogado) ? 'text-positive' : 'text-primary'">{{ lance.cliente.usuario.username }}</b>
                                            em <b>{{ formatDate(lance.data_hora) }}</b>
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-card>
                    </div>
                    <div :class="($q.screen.gt.sm) ? 'col-6' : 'col-12'" class="q-px-xs column">
                        <q-card class="flex column no-wrap full-width shadow-0">
                            <q-toolbar class="bg-primary text-white">
                                <q-icon name="mdi-gavel" />
                                <q-toolbar-title>
                                    Participar
                                </q-toolbar-title>
                            </q-toolbar>
                            <div style="height: 30vh; overflow-y: auto;" class="q-pa-sm">

                                <p class="q-mb-sm text-center" v-if="!logado">
                                    Para participar você precisar logar no sistema e habilitar-se no leilão.
                                </p>
                                <p class="q-mb-sm text-center" v-if="logado && habilitado">
                                    Você já está habilitado para este lote e já pode dar o seu lance!
                                </p>

                                <q-btn
                                    v-if="logado &&
                                          !lanceProprio &&
                                          habilitado &&
                                          +leilao.mostrar_direito_preferencia === 1 &&
                                          lote.direito_preferencia.map(item => +item.id_cliente).includes(+idClienteLogado) &&
                                          ['ABERTO', 'EM PREGAO', 'DOULHE_UMA', 'DOULHE_DUAS'].includes(lote.status_lote.identificador) &&
                                          ['ABERTO', 'EM_PREGAO'].includes(lote.leilao.status_leilao.identificador)"
                                    @click="abrindoLanceDireitoPreferencia(+lote.lanceAtual || +lote.proximoLance)"
                                    :class="'bg-primary'"
                                    class="text-white full-width hide-print q-mb-md"
                                    label="Exercer Direito de Preferência!"
                                />

                                <q-btn
                                    v-if="!habilitado"
                                    @click="habilitandoLocal = true"
                                    :class="'bg-primary'"
                                    class="text-white full-width hide-print"
                                    label="Habilite-se para dar seu lance!"
                                />
                                <q-btn
                                    v-else-if="!(['ABERTO', 'EM_PREGAO'].includes(lote.leilao.status_leilao.identificador))"
                                    disable
                                    :class="'bg-primary'"
                                    class="text-white full-width hide-print"
                                    :label="'O leilão está ' + lote.leilao?.status_leilao?.nome"
                                />
                                <q-btn
                                    v-else-if="!(['ABERTO', 'EM PREGAO', 'DOULHE_UMA', 'DOULHE_DUAS'].includes(lote.status_lote.identificador))"
                                    disable
                                    :class="'bg-positive'"
                                    class="text-white full-width hide-print"
                                    label="O lote não está recebendo mais lances."
                                />
                                <q-btn
                                    v-else-if="logado && !lanceProprio"
                                    @click="abrindoLance(+lote.proximoLance)"
                                    :class="'bg-positive'"
                                    class="text-white full-width hide-print"
                                    label="De Seu Lance!"
                                />
                                <template v-else >
                                    <q-btn
                                        v-if="logado"
                                        disable
                                        :class="'bg-positive'"
                                        class="text-white full-width hide-print"
                                        label="O seu lance está vencendo"
                                    />
                                </template>
                                <q-btn
                                    v-if="logado && habilitado
                                       && (['ABERTO', 'EM PREGAO', 'DOULHE_UMA', 'DOULHE_DUAS'].includes(lote.status_lote.identificador))
                                       && (['ABERTO', 'EM_PREGAO'].includes(lote.leilao.status_leilao.identificador))
                                       && +lote.leilao?.permitir_lance_automatico === 1"
                                    class="full-width q-mt-sm bg-primary hide-print q-pr-sm"
                                    @click="abrindoLanceAutomatico(+lote.proximoLance)"
                                    color="white"
                                    text-color="primary"
                                    :label="(temLanceAtomatico) ? 'Alterar Lance Automático': 'Configurar Lance Automático'"
                                />
                            </div>
                        </q-card>
                    </div>
                </div>
            </q-card-section>
            </q-card-section>
        <Lance
            v-model="lanceLocal"
            :leilao="lote.leilao"
            :lote="lote"
            :valor-lance="valorLanceAuditorio"
            :habilitado="habilitado"
            :automatico="false"
            :direitoPreferencia="false"
            @atualizacaoLanceAutomatico="atualizarLanceAutomatico"
        />
        <Lance
            v-model="LanceLocalDireitoPreferencia"
            :leilao="lote.leilao"
            :lote="lote"
            :valor-lance="valorLanceAuditorio"
            :habilitado="habilitado"
            :automatico="false"
            :direitoPreferencia="true"
            @atualizacaoLanceAutomatico="atualizarLanceAutomatico"
        />
        <Lance
            v-model="lanceLocalAutomatico"
            :leilao="lote.leilao"
            :lote="lote"
            :valor-lance="valorLanceAuditorio"
            :habilitado="habilitado"
            :automatico="true"
            :direitoPreferencia="false"
            :lanceAutomatico="lanceAutomatico"
            :lanceProprio="lanceProprio"
            @atualizacaoLanceAutomatico="atualizarLanceAutomatico"
        />
    </q-card>
</template>
<style>
.nopad > p{
    margin: 0;
}
</style>
