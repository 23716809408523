import script from "./Leilao.vue?vue&type=script&setup=true&lang=js"
export * from "./Leilao.vue?vue&type=script&setup=true&lang=js"

import "./Leilao.vue?vue&type=style&index=0&id=bff5b7dc&lang=css"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import QCarouselControl from 'quasar/src/components/carousel/QCarouselControl.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QCard,QCardSection,QSpace,QBtn,QResponsive,QCarousel,QCarouselSlide,QVideo,QCarouselControl,QChip,QImg,QSpinner,QIcon,QSeparator,QList,QItem,QItemSection,QItemLabel,QTooltip,QExpansionItem,QInfiniteScroll,QSpinnerDots,QToolbar,QToolbarTitle});qInstall(script, 'directives', {ClosePopup});
